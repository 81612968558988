<template>
  <div v-if="viewport" class="relation">
    <banner :nav-index="4">
      <template slot="banner">
        <div class="banner">
          <div class="center" :style="{'will-change': 'transform',transform: `translate3d(0,${transLateY}px,0)`}">
            <div class="text">
              <h2 class="distance">加入我们<br/> 创造未来</h2>
              <div class="line"></div>
              <div class="txt interval">
                致力于人机交互创新技术研发，扩展人类第六感。通过智能穿戴机器人技术，让人类实现数字与机器永生。
              </div>
              <div class="txt">
                通过智能穿戴机器人产品提升生活幸福感，让人们享受高品质的个性化健康交互服务。
              </div>
              <div class="btn">Reshape The Future.</div>
            </div>
          </div>
        </div>
      </template>
    </banner>
    <div style="position: relative;z-index: 2;background-color: #fff">
      <div class="collaborate">
        <div class="center">
          <div class="postbox">
            <h2>商务合作</h2>
            <p>邮箱</p>
            <p>bp@maschinerobot.com</p>
            <h1>Maschine Robot</h1>
            <h2>简历投递</h2>
            <p>邮箱</p>
            <p>hr@maschinerobot.com</p>
          </div>

        </div>
      </div>
      <relation></relation>
    </div>
  </div>
  <div v-else class="phone-wrap">
    <div class="background">
      <div class="nav">
        <div class="logo">
          <img src="@/assets/phone/phone-logo.png">
        </div>
        <div @click="onList" class="fork">
          <span class="iconfont icon-liebiaoshitucaidan"></span>
        </div>
      </div>
      <div class="title">
        <div class="pic">
          <img src="@/assets/phone/MaschineRobot.png">
        </div>
        <h2>加入我们</h2>
        <h2>创造未来</h2>
        <div class="line"></div>
      </div>

      <div v-show="list" class="list">
        <phoneNav @onHide="onHide"></phoneNav>
      </div>
    </div>
    <div class="txt">
      <p>致力于人机交互创新技术研发，扩展人类第六感。通过智能穿戴机器人技术，让人类实现数字与机器永生。
      </p>
      <p class="top">
        通过智能穿戴机器人产品提升生活幸福感，让人们享受高品质的个性化健康交互服务。
      </p>
    </div>
    <div class="content">
      <h2>商务合作</h2>
      <p>邮箱</p>
      <p>bp@maschinerobot.com</p>
      <h1>Maschine Robot</h1>
      <h2>简历投递</h2>
      <p>邮箱</p>
      <p>hr@maschinerobot.com</p>
    </div>
    <phoneFooter></phoneFooter>
  </div>
</template>

<script>
import banner from '@/components/banner'
import relation from '@/components/footer'
import phoneNav from '@/components/phoneNav/index'
import phoneFooter from "@/components/phoneFooter";

export default {
  name: "relation.vue",

  components: {
    phoneNav,
    banner,
    relation,
    phoneFooter,
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    this.screenWidth = document.body.clientWidth
    if (document.documentElement.clientWidth < 1280) {
      this.viewport = false
    }
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth

      })()
    }
  },
  data() {
    return {
      toTop: 0,
      transLateY: 0,
      viewport: true,
      screenWidth: null,
      list: false,
    }

  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    screenWidth: function (n, o) {
      if (n <= 1024) {
        this.viewport = false
      } else {
        this.viewport = true
      }
    }
  },
  methods: {

    onList() {
      setTimeout(() => {
        this.list = true
      }, 200)

    },

    onHide() {
      setTimeout(() => {
        this.list = false
      }, 200)

    },


    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop < 900) {
        if (this.toTop <= scrollTop) {
          this.transLateY = scrollTop / 10
        } else {
          this.transLateY = -scrollTop / 10
        }
      }
      this.toTop = scrollTop
    }
  }
}
</script>

<style scoped>

.center {
  width: 1200px;
  margin: 0 auto;
}

.center .text {
  padding-top: 100px;
}


.center .text h1 img {
  height: 56px;
}

.center .text .distance {
  margin-bottom: 24px;
}

.center .text h2 {
  font-weight: 600;
  font-size: 54px;
  line-height: 90px;
  color: #FFFFFF;
  letter-spacing: 1px;
}

.center .text .line {
  height: 2px;
  width: 50px;
  background-color: #FFFFFF;
}

.center .text .txt {
  color: #FFFFFF;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 1px;
}

.center .text .interval {
  margin-top: 44px;
  padding-bottom: 20px;
}

.center .text .btn {
  width: 350px;
  height: 71px;
  font-weight: 600;
  font-size: 34px;
  line-height: 71px;
  color: #FFFFFF;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin-top: 88px;
}

.collaborate {
  height: 900px;
}

.collaborate .postbox {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.collaborate .postbox h2 {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 54px;
  line-height: 76px;
}

.collaborate .postbox p {
  font-size: 24px;
  line-height: 42px;
}

.collaborate .postbox h1 {
  margin: 64px 0;
  font-size: 86px;
  color: #f2f4f9;
}

.phone-wrap {
  font-family: 'PingFang SC';
}

.nav {
  display: flex;
  justify-content: space-between;
  padding: 1.14rem 0.6rem 0;
}

.nav .logo {
  display: flex;
  align-items: center;
  height: 0.43rem;
}

.nav .logo img {
  height: 0.35rem;
}

.nav .fork {
  color: #FFFFFF;
  line-height: 0.43rem;
  height: 0.43rem;
}

.nav .fork .iconfont {
  font-size: 0.36rem;
}

.list {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.background {
  background-image: url("../../assets/phone/join.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.background .title {
  padding: 1.2rem 0 0.7rem 0.7rem;
  letter-spacing: 0.02rem;
}

.background .title .pic {
  padding-bottom: 0.5rem;
  line-height: 0;
}

.background .title .pic img {
  height: 0.28rem;
}

.background .title h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 0.36rem;
  line-height: 0.62rem;
  color: #FFFFFF;
  letter-spacing: 0.03rem;
}

.background .title .line {
  margin-top: 0.5rem;
  width: 6.11rem;
  height: 0.03rem;
  background-color: #FFFFFF;
}

.content {
  padding: 0.7rem 0;
  text-align: center;
}

.phone-wrap .txt {
  padding: 0.75rem 0.6rem 0.8rem;
}

.phone-wrap .txt p {
  font-size: 0.24rem;
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
}

.phone-wrap .txt .top {
  margin-top: 0.2rem;
}

.content h2 {
  padding-bottom: 0.2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.34rem;
  line-height: 0.48rem;
  letter-spacing: 0.03rem;
}

.content p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.26rem;
  line-height: 0.38rem;
  color: #111111;
}

.content h1 {
  margin-top: 0.6rem;
  padding-bottom: 0.6rem;
  color: #f2f4f9;
  font-size: 0.52rem;
}

.content .pic img {
  height: 0.46rem;
}
</style>